<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-card-actions>
                        <v-card-text class="pa-2">Filters</v-card-text>
                        <v-card-text class="pa-2 float-right text-right">
                            Status:
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn small v-on="on" class="ml-2">
                                        {{ filter.status }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list>
                                        <v-list-item
                                            v-for="item in filter.statusOptions"
                                            :key="item"
                                            @click="updateStatus(item)"
                                        >
                                            <v-list-item-title>{{
                                                item
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-menu>
                        </v-card-text>
                    </v-card-actions>
                    <v-divider />
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">ID</th>
                                    <th class="text-left">Customer</th>
                                    <th class="text-left">Transaction ID</th>
                                    <th class="text-left">Amount</th>
                                    <th class="text-left">Status</th>
                                    <th class="text-left">Card</th>
                                    <th class="text-left">Message</th>
                                    <th class="text-left">Date/Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in data.data" :key="i">
                                    <td>{{ item.i_payment_transaction }}</td>
                                    <td>
                                        <router-link
                                            :to="
                                                '/customers/' + item.i_customer
                                            "
                                            >{{
                                                item.customer_name
                                            }}</router-link
                                        >
                                    </td>
                                    <td>{{ item.x_transaction_id }}</td>
                                    <td>
                                        <Currency :value="item.amount" />
                                    </td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.payment_method_info }}</td>
                                    <td>{{ item.result_message }}</td>
                                    <td>
                                        <LocalDateTime
                                            :value="item.timestamp"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '../../components/cards/templates/PaginationCard';
import LocalDateTime from '../../components/pieces/LocalDateTime';
import Currency from '../../components/pieces/Currency';
import apiMixin from '../../mixins/apiMixin';
export default {
    name: 'CreditCardCharges',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            status: 'COMPLETED',
            statusOptions: [
                'ALL',
                'STARTED',
                'AUTHORIZED',
                'COMPLETED',
                'FAILED',
                'CANCELLED',
                'VOIDED',
            ],
        },
        loading: false,
        data: [],
    }),
    components: {
        Currency,
        LocalDateTime,
        PaginationCard,
    },
    methods: {
        async update() {
            this.loading = true;
            const response = await this.Api.send('post','reporting/creditcard', {
                limit: this.filter.limit,
                page: this.filter.page,
                status: this.filter.status,
            });
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateStatus(data) {
            this.filter.status = data;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
