<template>
    <SimpleDialogOld
        :visible="visible"
        :loading="busy"
        title="Un-Approve Sign-up Request"
        persistent
        @close="$emit('close')"
        @click="reject"
    >
        <p>Are you sure you want to un-approve this sign-up request?</p>
    </SimpleDialogOld>
</template>

<script>
import SimpleDialogOld from '../templates/SimpleDialogOld';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'UnapproveSignup',
    mixins: [apiMixin],
    components: { SimpleDialogOld },
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            signup_id: null,
            Api: [],
        };
    },
    props: {
        data: {},
        visible: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        if (this.data.signup_id) {
            this.signup_id = this.data.signup_id.toString();
        }
    },
    methods: {
        async reject() {
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.signup_id + '/unapprove',
                {}
            );
            if (response.success) {
                this.$emit('save');
                this.$emit('close');
                this.busy = false;
            } else {
                this.busy = false;
            }
        },
    },
};
</script>
